import React from 'react';
import './modal-styles.css';

function Modal({ isOpen, onClose }) {
    if (!isOpen) return null;
  
    return (
      <div className="modal">
        <div className="modal-content">
          <span onClick={onClose} className="close-button">
            &times;
          </span>
          <h1>Contatos</h1>
          <div className="contact-info">
            <div className="contact-item">
              <i className="fa fa-phone"></i>
              <h2>+55 91 98220-9840</h2>
            </div>
            <div className="contact-item">
              <i className="fa fa-envelope"></i>
              <h2>giheloise7@gmail.com</h2>
            </div>
            <div className="contact-item">
              <i className="fa fa-instagram"></i>
              <h2>@giheloise7</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Modal;
