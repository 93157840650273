import './who-am-i-styles.css';

const WhoAmI = ({ title, modality, obs, valuePerMinute, totalValue }) => (
    <div id="who-am-i">
        <div className="who-am-i-div">
            <img className='profile-image' src='me.jpg' alt='Foto de Giovanna Heloise'></img>
            <div className='profile-infos'>
                <h1>Giovanna Heloise Barbosa de Carvalho</h1>
                <h2>Líder da empresa</h2>
                <h2>Bacharel em Tradutor e Intérprete pelo Centro Educacional Adventista de São Paulo - Campus Engenheiro Coelho (UNASP-EC)</h2>
                <h2>Pós-Graduanda em Tradução Audiovisual pela Estácio</h2>
            </div>
        </div>
    </div>
);

export default WhoAmI;