import React, { useState } from 'react';
import Modal from './modal/Modal';
import './header-styles.css';

function Header() {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <header id="header">
        <div className="header-div">
            <div className="header-logo">
            <img src='logo.png' alt='Logo' width={50}></img>
            </div>
            <div >
                <ul>
                    <li className='header-options'>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/who-am-i">Quem somos</a>
                    </li>
                    <li>
                        <a href="/portfolio">Portfólio</a>
                    </li>
                    <li>
                        <a href="/#" onClick={modalOpen ? closeModal : openModal}>Contato</a>
                    </li>
                </ul>
            </div>
        </div>
        <Modal isOpen={modalOpen} onClose={closeModal} />
        </header>
    );
};

export default Header;