import './home-styles.css';

const Home = () => (
    <div id="home">
        <div className="home-div">
            <h1 className='home-title'>Excelência em Tradução: Líderes no Mundo das Palavras</h1>
            <div className='subtitle-div'>
                <h2>
                    “Traduzir é como cruzar um rio transportando palavras, imagens, sentimentos, metáforas, ideias, histórias, culturas. Em cada margem está um povo, uma língua, uma cultura, cada qual com seu modo de ver e interpretar o mundo.”
                </h2>
            </div>
            <h2>Rainer Schulte</h2>
            <img src='logo.png' alt='Logo'></img>
            <img className='home-image' src='company-image.jpg' alt='Foto da GH Traduções'></img>
        </div>
    </div>
);

export default Home;