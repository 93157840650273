import './footer-styles.css';

const Footer = () => (
    <div id="footer">
        {/* <div className='footer-div'>
        </div> */}
        <div className="copyright-div">
            <h1>© Copyright 2023 by GH Traduções</h1>
        </div>
    </div>
);

export default Footer;