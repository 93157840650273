import './card-styles.css';

const Card = ({ title, index, modality, obs, link, valuePerMinute, totalValue }) => (
    <div id="card">
        <div className="card-div">
            <div className="card-title-div">
                <h1 className='no-margin no-center'>#{index}. {title}</h1>
            </div>
            <div className = 'card-center-content'>
                <div className='card-left-content'>
                    <h2 className='no-margin'>{obs}</h2>
                    <h2 className='no-margin some-height'><span className='bold'>Modalidade:</span> {modality}</h2>
                </div>
                <a href={link} target="blank">
                    <div className='card-right-content'>
                        <div className='card-image-container'>
                            <img className='card-image' src='right-arrow.png' alt='Link de redirecionamento'></img>
                        </div>
                    </div>
                </a>
            </div>
            {/*
            <h3 className='no-margin'><span className='bold'>Valor p/ minuto:</span> {valuePerMinute}</h3>
            <h3 className='no-margin'><span className='bold'>Valor total:</span> {totalValue}</h3> */}
        </div>
    </div>
);

export default Card;