import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './app/features/header/Header';
import Home from './app/features/home/Home';
import Portfolio from './app/features/portfolio/Portfolio';
import Footer from './app/features/footer/Footer';
import WhoAmI from './app/features/who-am-i/WhoAmI';
import './default-styles.css';

function App() {
  return (
    <div className='background-container'>
      <BrowserRouter>
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/who-am-i" element={<WhoAmI />} />
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes> 
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
