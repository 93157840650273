import './portfolio-styles.css';
import Card from './card/Card';

const Portfolio = () => (
    <div id="portfolio">
        <div className="portfolio-div">
            <div className='portfolio-row'>
                <Card title={'The Apps Show'} index={'1'} modality={'Tradução para Legendagem'} obs={'Tradução para legendagem do episódio do programa Google Apps que fala sobre o Grupos Google.'} valuePerMinute={'R$ 25,00 | +30% de marcação | +20% de queima'} totalValue={'R$ 337,61'} link={'https://drive.google.com/file/d/1M4ehlhKYXt-22sDFbZf-JNJKveYy9pT0/view?usp=sharing'} />
            </div>
            <div className='portfolio-row'>
                <Card title={'Edutopia'} index={'2'} modality={'Tradução para Legendagem'} obs={'Tradução para legendagem do trecho de uma entrevista da fundação Edutopia com Martin Scorsese, cineasta, produtor de cinema, roteirista e ator norte-americano.'} valuePerMinute={'R$ 25,00 | +30% de marcação | +20% de queima'} totalValue={'R$ 585,13'} link={'https://drive.google.com/file/d/1-thJpd1pUy_5BkmMz3MeoU13XbIawAxd/view?usp=sharing'} />
            </div>
            <div className='portfolio-row'>
                <Card title={'JOSEPH'} index={'3'} modality={'Tradução para Dublagem'} obs={'Tradução para dublagem do filme Joseph: King of Dreams, filme de animação produzido pela DreamWorks Animation.'} valuePerMinute={'R$ 50,00 | +30% sem script'} totalValue={'R$ 158,17'} link={'https://docs.google.com/document/d/1ZT4o2MIOTHhoUIHDZ5KQUZe0yHmB98_3/edit?usp=sharing&ouid=112657817717540362098&rtpof=true&sd=true'} />
            </div>
            <div className='portfolio-row'>
                <Card title={'Priscila - A rainha do deserto'} index={'4'} modality={'Tradução para Dublagem'} obs={'Tradução para dublagem do filme australiano de comédia dramática e musical Priscila - A rainha do deserto.'} valuePerMinute={'R$ 50,00'} totalValue={'R$ 231,67'} link={'https://docs.google.com/document/d/1PfUyJPtqUrxMiEih-592_qZeFU5S3wtC/edit?usp=sharing&ouid=112657817717540362098&rtpof=true&sd=true'} />
            </div>
            <div className='portfolio-row'>
                <Card title={'Central do Brasil'} index={'5'} modality={'Audiodescrição'} obs={'Audiodescrição de uma cena do filme de drama brasileiro Central do Brasil.'} valuePerMinute={'R$ 50,00 | +30% sem script'} totalValue={'R$ 321,75'} link={'https://docs.google.com/document/d/1gkzV5NfOQ673Tv8ZEn8JZxB37TZpmKzT/edit?usp=sharing&ouid=112657817717540362098&rtpof=true&sd=true'} />
            </div>
            <div className='portfolio-row'>
                <Card title={'Divórcio'} index={'6'} modality={'Audiodescrição'} obs={'Audiodescrição de uma cena do filme de comédia brasileiro Divórcio.'} valuePerMinute={'R$ 50,00 | +30% sem script'} totalValue={'R$ 187,42'} link={'https://docs.google.com/document/d/1UCXt3-wCAdu8yzwdPYA7cVUDM7Ii7QPr/edit?usp=sharing&ouid=112657817717540362098&rtpof=true&sd=true'} />
            </div>
            <div className='portfolio-row'>
                <Card title={'A mulher invisível'} index={'7'} modality={'LSE'} obs={'Legendagem para surdos e ensurdecidos do filme de comédia brasileiro A Mulher Invisível.'} valuePerMinute={'R$ 12,50 | +30% sem script | +30% de marcação | +20% de queima'} totalValue={'R$ 181,68'} link={'https://drive.google.com/file/d/1U3-kK3oCbfUAruyplH7xIYAjHASiFgn1/view?usp=sharing'} />
            </div>
            <div className='portfolio-row'>
                <Card title={'8 minutos'} index={'8'} modality={'LSE'} obs={'Legendagem para surdos e ensurdecidos de uma entrevista do programa 8 minutos com Márcio Ballas, palhaço, improvisador, apresentador e diretor especializado na linguagem de Clown e Improviso Teatral.'} valuePerMinute={'R$ 12,50 | +30% sem script | +30% de marcação | +20% de queima'} totalValue={'R$ 225,62'} link={'https://drive.google.com/file/d/1jLSwuK97xOFazvFv-Nu3WkWXcifPIHxL/view?usp=sharing'} />
            </div>
        </div>
    </div>
);

export default Portfolio;